import { createApp } from "vue"
import App from "./App.vue"

//Vuetify
import "vuetify/styles"
import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"

//Main scss
//import "./assets/scss/main.scss"

const lightTheme = {
  dark: false,
  colors: {
    background: '#e4e8e3',
    primary: '#4bd63e'
  }
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      lightTheme,
    }
  }
})

createApp(App).use(vuetify).mount('#app')
